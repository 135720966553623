import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Card from './Card'
import Loader from './Loader'

function UserProfile({
  builds,
  currentUser,
  onFollow,
}: {
  builds: any
  currentUser: string
  onFollow: (username: string, isFollowing: boolean) => void
}) {
  const { username } = useParams()
  const [user, setUser] = useState<any>(null)
  const [userBuilds, setUserBuilds] = useState<any>([])
  const [isFollowing, setIsFollowing] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // In a real app, you'd fetch user data from an API
    // For now, we'll simulate this with a timeout
    setIsLoading(true)
    setTimeout(() => {
      setUser({
        username: username as string,
        avatar: 'https://api.dicebear.com/9.x/pixel-art/svg',
        //   `https://avatars.dicebear.com/api/initials/${username}.svg` ||
        bio: `Hi, I'm ${username}. I love building PCs!`,
        followers: 100,
        following: 50,
      })
      setUserBuilds(builds.filter((build: any) => build.username === username))
      setIsLoading(false)
    }, 1500) // Increased timeout to 1.5 seconds to make the loader more noticeable
  }, [username, builds])

  useEffect(() => {
    // Check if the current user is following this user
    // In a real app, this would be part of the user data fetched from the API
    setIsFollowing(Math.random() < 0.5)
  }, [currentUser, username])

  const handleFollow = () => {
    setIsFollowing(!isFollowing)
    onFollow(username as string, !isFollowing)
  }

  if (isLoading) {
    return <Loader />
  }

  if (!user) {
    return <div>User not found</div>
  }

  return (
    <div className="user-profile">
      <div className="user-header">
        <img src={user.avatar} alt={user.username} className="user-avatar" />
        <h2>{user.username}</h2>
        <p>{user.bio}</p>
        <div className="user-stats">
          <span>{user.followers} Followers</span>
          <span>{user.following} Following</span>
        </div>
        {currentUser && currentUser !== username && (
          <button onClick={handleFollow} className="follow-button">
            {isFollowing ? 'Unfollow' : 'Follow'}
          </button>
        )}
      </div>
      <h3>Builds by {user.username}</h3>
      <div className="user-builds">
        {userBuilds.map((build: any) => (
          <Card key={build.id} {...build} />
        ))}
      </div>
    </div>
  )
}

export default UserProfile
