import React from 'react'
import './TotalPrice.css'

function TotalPrice({ price }: { price: number }) {
  return (
    <div className="total-price">
      <h3>Rig Price</h3>
      <div className="price-container">
        <span className="price-value">${price.toFixed(2)}</span>
      </div>
    </div>
  )
}

export default TotalPrice
