import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './AddRig.css'

interface AddRigProps {
  onAddRig: (newRig: Rig) => void
  onClose: () => void
}

interface Rig {
  id: string
  title: string
  description: string
  username: string
  likes: number
  components: { name: string; price: number; purchaseLink: string }[]
}

const AddRig: React.FC<AddRigProps> = ({ onAddRig, onClose }) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [components, setComponents] = useState<
    { name: string; price: number; purchaseLink: string }[]
  >([])
  const navigate = useNavigate()

  const handleAddComponent = () => {
    setComponents([...components, { name: '', price: 0, purchaseLink: '' }])
  }

  const handleComponentChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const newComponents = [...components]
    newComponents[index] = { ...newComponents[index], [field]: value }
    setComponents(newComponents)
  }

  const handleSubmit = () => {
    const newRig: Rig = {
      id: Date.now().toString(),
      title,
      description,
      username: 'currentUser', // Replace with actual current user
      likes: 0,
      components,
    }
    onAddRig(newRig)
    navigate('/')
  }

  const handleClose = () => {
    navigate('/')
  }

  return (
    <div className="add-rig-container">
      <h2>Add a New Rig</h2>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <textarea
        placeholder="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <h3>Components</h3>
      {components.map((component, index) => (
        <div key={index} className="component-inputs">
          <input
            type="text"
            placeholder="Name"
            value={component.name}
            onChange={(e) =>
              handleComponentChange(index, 'name', e.target.value)
            }
          />
          <input
            type="number"
            placeholder="Price"
            value={component.price}
            onChange={(e) =>
              handleComponentChange(index, 'price', parseFloat(e.target.value))
            }
          />
          <input
            type="text"
            placeholder="Purchase Link"
            value={component.purchaseLink}
            onChange={(e) =>
              handleComponentChange(index, 'purchaseLink', e.target.value)
            }
          />
        </div>
      ))}
      <button onClick={handleAddComponent} className="add-component-button">
        Add Component
      </button>
      <button onClick={handleSubmit} className="submit-button">
        Submit
      </button>
      <button onClick={handleClose} className="close-button">
        Close
      </button>
    </div>
  )
}

export default AddRig
