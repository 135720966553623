import React, { useState } from 'react'

interface LoginFormProps {
  onLogin: (username: string, password: string) => void
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onLogin(username, password)
  }

  return (
    <div className="login-box">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Log In</button>
      </form>
      <div className="social-login">
        <button onClick={() => onLogin('GoogleUser', 'google')}>
          Login with Google
        </button>
        <button onClick={() => onLogin('TwitchUser', 'twitch')}>
          Login with Twitch
        </button>
      </div>
    </div>
  )
}

export default LoginForm
