import React from 'react'
import './PerformanceScale.css'

function PerformanceScale({ score }) {
  const maxScore = 10
  const percentage = (score / maxScore) * 100

  return (
    <div className="performance-scale">
      <h3>Performance Scale</h3>
      <div className="scale-container">
        <div className="scale-bar" style={{ width: `${percentage}%` }}>
          <span className="scale-score">{score}</span>
        </div>
      </div>
      <div className="scale-labels">
        <span>Low</span>
        <span>Medium</span>
        <span>High</span>
      </div>
    </div>
  )
}

export default PerformanceScale
