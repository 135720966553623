import React from 'react'
import { useParams } from 'react-router-dom'
import PerformanceScale from './PerformanceScale'
import TotalPrice from './TotalPrice'
import UserInfo from './UserInfo'

interface Rig {
  id: string
  title: string
  description: string
  username: string
  likes: number
  components: { name: string; price: number; purchaseLink: string }[]
}

interface BuildDetailsProps {
  builds: Rig[]
  isLoggedIn: boolean
  userAvatar: string
}

function BuildDetails({ builds, isLoggedIn, userAvatar }: BuildDetailsProps) {
  const { id } = useParams<{ id: string }>()
  const build = builds.find((b) => b.id === id)

  if (!build) return <div>Build not found</div>

  const totalPrice = build.components.reduce(
    (sum, component) => sum + component.price,
    0
  )

  return (
    <main className="main-content build-details">
      <h1>{build.title}</h1>
      <UserInfo username={build.username} avatar={userAvatar} />
      <p className="build-description">{build.description}</p>

      <div className="build-summary">
        <TotalPrice price={totalPrice} />
        <PerformanceScale score={7} />{' '}
        {/* You might want to add a performance score to your Rig interface */}
      </div>

      <table className="components-table">
        <thead>
          <tr>
            <th>Component</th>
            <th>Price</th>
            <th>Purchase Link</th>
          </tr>
        </thead>
        <tbody>
          {build.components.map((component, index) => (
            <tr key={index}>
              <td>{component.name}</td>
              <td>${component.price.toFixed(2)}</td>
              <td>
                <a
                  href={component.purchaseLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  )
}

export default BuildDetails
