import React from 'react'
import MyRigs from './MyRigs'
import FavoriteRigs from './FavoriteRigs'
import Card from './Card'
import './Feed.css'

interface Rig {
  id: string
  title: string
  description: string
  username: string
  likes: number
}

interface FeedProps {
  userRigs: Rig[]
  favoriteRigs: Rig[]
  allRigs: Rig[]
  onLike: (rigId: string) => void
  onFavorite: (rigId: string) => void
}

const Feed: React.FC<FeedProps> = ({
  userRigs,
  favoriteRigs,
  allRigs,
  onLike,
  onFavorite,
}) => {
  return (
    <div className="feed-container">
      <MyRigs rigs={userRigs} onLike={onLike} onFavorite={onFavorite} />
      <FavoriteRigs
        rigs={favoriteRigs}
        onLike={onLike}
        onFavorite={onFavorite}
      />
      <h2 className="rig-section-heading">All Rigs</h2>
      <div className="card-grid">
        {allRigs
          .sort((a, b) => b.likes - a.likes)
          .map((rig) => (
            <Card
              key={rig.id}
              {...rig}
              onLike={() => onLike(rig.id)}
              onFavorite={() => onFavorite(rig.id)}
            />
          ))}
      </div>
    </div>
  )
}

export default Feed
