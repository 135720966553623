import React, { useState, useEffect } from 'react'
import './App.css'
import MainApp from './MainApp'

const App: React.FC = () => {
  const isLocalhost =
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  const [isLaunched, setIsLaunched] = useState(isLocalhost)
  const [isAdmin, setIsAdmin] = useState(isLocalhost)

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    setIsAdmin(isAdmin || queryParams.get('admin') === 'true')
  }, [isAdmin])

  if (isLaunched) {
    return <MainApp />
  }

  return (
    <div className="container">
      <h1>RigBrag</h1>
      <p>
        Get ready to showcase your ultimate PC builds! RigBrag.com is coming
        soon, bringing you a platform to share and compare hardware specs like
        never before.
      </p>
      <div className="countdown">{'Launching soon!'}</div>
      {isAdmin && (
        <button onClick={() => setIsLaunched(true)}>Preview Main App</button>
      )}
    </div>
  )
}

export default App
