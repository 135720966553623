import React from 'react'
import { Link } from 'react-router-dom'
import './UserInfo.css'

function UserInfo({ username, avatar }: { username: string; avatar: string }) {
  return (
    <div className="user-info-container">
      <Link to={`/user/${username}`} className="user-link">
        <img src={avatar} alt={username} className="user-avatar-small" />
        <span className="username">{username}</span>
      </Link>
    </div>
  )
}

export default UserInfo
