import React from 'react'
import Card from './Card'

interface Rig {
  id: string
  title: string
  description: string
  username: string
  likes: number
}

interface MyRigsProps {
  rigs: Rig[]
  onLike: (rigId: string) => void
  onFavorite: (rigId: string) => void
}

const MyRigs: React.FC<MyRigsProps> = ({ rigs, onLike, onFavorite }) => {
  return (
    <div className="my-rigs">
      <h2 className="rig-section-heading">My Rigs</h2>
      <div className="card-grid">
        {rigs.map((rig) => (
          <Card
            key={rig.id}
            {...rig}
            onLike={() => onLike(rig.id)}
            onFavorite={() => onFavorite(rig.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default MyRigs
