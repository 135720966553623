import React from 'react'
import { Link } from 'react-router-dom'

function Header({
  isLoggedIn,
  userAvatar,
}: {
  isLoggedIn: boolean
  userAvatar: string
}) {
  return (
    <header className="header sticky">
      <Link to="/" className="logo-link">
        <h1>RigBrag</h1>
      </Link>
      <div className="user-info">
        {isLoggedIn ? (
          <img src={userAvatar} alt="User Avatar" className="avatar" />
        ) : (
          <button className="login-button">Log In</button>
        )}
      </div>
    </header>
  )
}

export default Header
